import React from 'react';

export const Faq = ({wrapClassName, question, answer}) => {
    return (
        <div className={wrapClassName}>
            <div className="faq-box bg-success">
                <h4 className="mb-10">{question}</h4>
                <p className="mb-0">{answer}</p>
            </div>
        </div>
    );
}