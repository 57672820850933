import {React} from 'react';

export const Button = ({type = 'anchor', button}) => {
    const handleClick = (evt) => {
        evt.preventDefault();

        let element = document.querySelector(evt.target.getAttribute('href'));

        element?.scrollIntoView({
            behavior: 'smooth'
        });


    }
    return (
        type === 'anchor'
        ? <a
            href={button.href}
            className={`${button.className}`}
            onClick={handleClick}
            >
                {button.title}
            </a>
        : <button
            className={`btn ${button.className}`}
            onClick={handleClick}
            >
            {button.title}
            </button>
    );
}