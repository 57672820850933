import React from "react";
import { Button } from "../Button/Button";

export const HeroText = ({title, subtitle, content, list, button}) => {

    return (
        <div className="hero-text">
            <div className="hero-text">
                <h3 className="mb-5">{subtitle}</h3>
                <h1 className="mb-25">{title}</h1>
                <p className="mb-30 mw-5">{content}</p>

                {list !== undefined
                    ? <ul className="list-group pl-20 mb-30">  {list.map((item, index) => <li key={index}>{item}</li>)} </ul>
                    : ''
                }
                {button
                    ? <Button
                        type="anchor"
                        button={button}
                        className={`btn ${button.className}`}
                    />
                    : ''
                }
            </div>
        </div>
    );
}