import React from "react";
import { FeaturedItem } from "../FeaturedItem/FeaturedItem";
import { HeroText } from "../HeroText/HeroText";

// Why Choose Us
export const Featured = () => {
    return (
        <div className="bg-2 bg-white mt-120" id="about-us">
            {/* <!-- Featured Section Start --> */}
            <section className="section-pb">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <div className="heading mb-80">
                                <h3>¿Porque elegirnos a nosotros?</h3>
                                <h1 className="mb-25">Nuestros beneficios empresariales</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <FeaturedItem
                            wrapClassName="col-12 col-md-6 col-lg-3 mb-sm-30 mb-md-30"
                            icon="ti-rocket"
                            title="Concepto e Idea"
                            description="Te ofrecemos herramientas intuitivas que se adaptan a tus necesidades, optimizando cada proceso para que puedas enfocarte en lo que realmente importa en materia de recurso humano."
                        />
                        <FeaturedItem
                            wrapClassName="col-12 col-md-6 col-lg-3 mb-sm-30 mb-md-30"
                            icon="ti-user"
                            title="Experiencia del Usuario"
                            description="Creamos una interfaz amigable que hace que cada interacción sea fluida y agradable, mejorando la productividad de todos."
                        />
                        <FeaturedItem
                            wrapClassName="col-12 col-md-6 col-lg-3 mb-sm-30"
                            icon="ti-cloud"
                            title="Desarrollo Web"
                            description="Nuestra plataforma está diseñada para ser accesible y funcional, brindándote un entorno de trabajo excepcional."
                        />
                        <FeaturedItem
                            wrapClassName="col-12 col-md-6 col-lg-3 mb-sm-30"
                            icon="ti-book"
                            title="Identidad de Marca"
                            description="Fortalece la cultura de tu empresa con herramientas que reflejan tus valores y visión, creando un ambiente inspirador."
                        />
                    </div>
                </div>
            </section>
            {/* <!-- Hero Block Start --> */}
            <section className="hero-block mt-30">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-6 mb-sm-30">
                            <HeroText
                                subtitle="¡Dinamiza la gestión"
                                title="del recurso humano con nuestro sistema!"
                                content={["En nuestro sistema, ",<strong>tenemos todo lo que necesitas</strong>, " para gestionar tu equipo de manera eficiente. Desde la planificación hasta la ejecución, te acompañamos en cada paso del proceso.",
                                <span className="d-block" />,
                                "¡No dejes que la información y el control del recurso humano se quede en el aire!"]}
                                button={{href: "#contact-us", className: "btn btn-primary", title: "Iniciemos"}}
                            />
                        </div>
                        <div className="col-12 col-md-6 ml-auto">
                            <div className="hero-thumb m-minus-60">
                                <img loading="lazy" src="assets/img/slider/reunion.jpg" alt="Reunion de equipo" width="615" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Timeline Line Start --> */}
            <div className="timeline-line">
                <div className="container">
                    <div className="timeline-box position-relative">
                        <div className="move-line position-absolute topLeft">
                            <hr className="line" />
                        </div>
                        <div className="move-line position-absolute mx-auto center">
                            <hr className="line" />
                        </div>
                        <div className="move-line position-absolute bottomRight">
                            <hr className="line" />
                        </div>
                        <div className="dot bg-primary left-dot"></div>
                        <div className="dot bg-primary right-dot"></div>
                    </div>
                </div>
            </div>
            {/* <!-- Hero Block Start --> */}
            <section className="hero-block section-pb">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-6 col-xl-5 mb-sm-30">
                            <div className="hero-thumb m-minus-70">
                            <img loading="lazy" src="assets/img/hero/meet-2.jpg" alt="Hero Thumbnail" width="543" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6 ml-auto">
                            <HeroText
                                subtitle="¿Qué nos "
                                title="diferencia?"
                                content=""
                                list={[
                                    [
                                        <strong>Soluciones Instantáneas:</strong>,
                                        "Nuestros paquetes están diseñados para ofrecerte ",
                                        <strong>respuestas rápidas y efectivas</strong>,
                                        " a tus desafíos de gestión de talento."
                                    ],
                                    [
                                        <strong>Diseño Profesional:</strong>,
                                        " Cada paquete incluye un enfoque estético y funcional. Creamos ",
                                        <strong>soluciones dinámicas</strong>,
                                        " que reflejan la identidad de tu empresa y mejoran la experiencia de tu equipo."
                                    ],
                                    [
                                        <strong>Consulta Gratuitas & Demos:</strong>,
                                        " No te quedes con dudas. Ofrecemos una ",
                                        <strong>consulta gratuita</strong>,
                                        " y manejo de un perfil demo para interactuar. Nuestro objetivo es poder entender tus necesidades y mostrarte cómo nuestros servicios pueden transformar tu gestión de recursos humanos."
                                    ]
                                ]}
                                button={{href: "#contact-us", className: "btn btn-primary", title: "Iniciemos"}}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}