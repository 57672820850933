import React from "react";
import { Button } from "../Button/Button";
import './footer.scss';

export const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-widget-area bg-light ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-5 mb-sm-40">
                                <div className="footer-widget about">
                                    <div className="footer-logo mb-20">
                                        <Button
                                            type="anchor"
                                            button={{href:"#home", title: "SYS HUMAN RH"}}
                                        />
                                    </div>
                                    <div className="widget-content">
                                        <p>SYS HUMAN RH es uno de los productos con los que cuenta <strong>Servicios Integrales JV&JA</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright bg-white ptb-15">
                    <div className="container d-sm-flex justify-content-center">
                        <p className="mb-0">Copyrights &copy; 2024 Todos los derechos reservados <a href="https://serviciosintegralesjvja.com" target="_blank" rel="noreferrer"><strong>Servicios Integrales JV&JA</strong></a></p>
                    </div>
                </div>
            </footer>
        </>
    );
}