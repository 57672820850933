import React from 'react';
import { Card } from '../Card/Card';


export const HowWork = () => {
    return (
        <div className="how-work section-ptb bg-light" id='process'>
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <div className="heading mb-110">
                            <h3>Proceso de Trabajo</h3>
                            <h1 className="mb-25">Cómo Funciona?</h1>
                        </div>
                    </div>
                </div>
                <div className="row text-center position-relative">
                    <div className="move-line mx-auto position-absolute">
                        <hr className="line"/>
                    </div>
                    <Card
                        wrapClassName="col-12 col-md-4 mb-sm-50"
                        icon="ti-light-bulb"
                        title="Conceptualización"
                        content="Se generan y refinan ideas iniciales para establecer la dirección del proyecto. Esta etapa es crucial para definir objetivos y visualizar el resultado final."
                    />

                    <Card
                        wrapClassName="col-12 col-md-4 mb-sm-50"
                        icon="ti-layout"
                        title="Diseño"
                        content="Se desarrolla la estructura y apariencia visual del proyecto, asegurando que el diseño sea funcional y atractivo. Esta fase da forma a la idea, preparándola para su implementación."
                    />

                    <Card
                        wrapClassName="col-12 col-md-4"
                        icon="ti-dashboard"
                        title="Desarrollo"
                        content="Se lleva a cabo la implementación y ajuste del diseño. Aquí, se materializa el concepto mediante el uso de herramientas y metodologías que garantizan la calidad y funcionalidad del producto final."
                    />
                </div>
            </div>
        </div>
    );
}