import React from "react";
import { Faq } from "../Faq/Faq";

export const Faqs = () => {

    return (
        <section className="section-ptb bg-light" id="faqs">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <div className="heading mb-80">
                            <h2>Preguntas frecuentes</h2>
                            <p className="mb-20 d-none">Looked up one of the more obscure Latin words, consectetur, from <br className="d-none d-md-block"/> a Lorem Ipsum passage, and going</p>
                            <hr className="line bw-2 mx-auto line-sm mb-5"/>
                            <hr className="line bw-2 mx-auto"/>
                        </div>
                    </div>
                </div>
                <div className="row mb-30">
                    <Faq
                        wrapClassName="col-12 col-md-6 mb-sm-30"
                        question="01. Qu&eacute; puedo administrar en el sistema?"
                        answer="En el sistema puede gestionar: Departamentos, Empleados, Usuarios, Incapacidades, Amonestaciones, Planilla, Recordatorios, Reportes, C&aacute;lculo Aguinaldo, Vacaciones."
                    />
                    <Faq
                        wrapClassName="col-12 col-md-6"
                        question="02. Qué tipo de reportes se genera?"
                        answer="Tiene la opci&oacute;n exportar reportes en excel, PDF o enviar por email, puede generar comprbantes de pago."
                    />
                </div>
                <div className="row">
                    <Faq
                        wrapClassName="col-12 col-md-6 mb-sm-30"
                        question="03. Existe alg&uacute;n demo del sistema?"
                        answer="Contamos con un demo para que puede revisarlo y ver si se ajusta a sus necesidades."
                    />
                    <Faq
                        wrapClassName="col-12 col-md-6"
                        question="04. Puedo tener varios usuarios para administrar el sistema?"
                        answer="S&iacute, el sistema cuenta con m&oacute;dulo de usuarios, ud puede agregar, modificar o eliminar usuarios."
                    />
                </div>
            </div>
        </section>
    );
}