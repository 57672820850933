import { ContactUs } from "./components/ContactUs/ContactUs";
import { Faqs } from "./components/Faqs/Faqs";
import { Featured } from "./components/Featured/Featured";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { HowWork } from "./components/HowWork/HowWork";
import { Promo } from "./components/Promo/Promo";
import { Slider } from "./components/Slider/Slider";
import './styles/styles.scss';

function App() {
  return (
    <>
      <Header />
      <Slider />
      <Featured />
      <HowWork />
      {/* Pricing */}
      <Promo />
      {/* Testimonial */}
      <Faqs />
      {/* Clients */}
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
