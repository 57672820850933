import React from 'react';

export const Card = ({icon, title, content, wrapClassName}) => {
    return (
        <div className={ wrapClassName }>
            <div className="work-box">
                <div className="card-body bg-white">
                    <div className="icon bg-primary mx-auto">
                        <i className={ icon }></i>
                    </div>
                    <h3>{ title }</h3>
                    <p>{ content }</p>
                </div>
            </div>
        </div>
    );
}