import React from "react";
import './featured-item.scss';

export const FeaturedItem = ({wrapClassName, icon, title, description, link}) => {
    return (
        <div className={wrapClassName}>
            <div className="card featured-item">
                <div className="card-body ptb-45">
                    <div className="icon circle-icon mb-30 mx-auto">
                        <i className={icon}></i>
                    </div>
                    <h5>{title}</h5>
                    <p className="mb-20">{description}</p>
                    {link
                        ? <a className="item-link link-btn" href={link.href}>{link.title}</a>
                        : ''
                    }
                </div>
            </div>
        </div>
    );
}